
import { Component, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import StepTracker from "@/components/request/StepTracker.vue";
// import SearchServiceNew from "@/components/request/SearchServiceNew.vue";
import DialogService from "@/components/request/DialogService.vue";
import DetailService from "@components/request/DetailService.vue";

@Component({
  components: {
    LogoSingle,
    StepTracker,
    // SearchServiceNew,
    DialogService,
    IdentifyUser: () => import("@/components/request/IdentifyUser.vue"),
    SendRequest: () => import("@/components/request/SendRequest.vue"),
    EndRequest: () => import("@/components/request/EndRequest.vue")
  }
})
export default class NewRequest extends Vue {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  private service: any = {};
  private motive: any = {};

  private mounted() {
    this.selectService();
    this.$store.dispatch("cleanTicket");
    window.scroll(0, 0);
  }

  private stepOneSubmit() {
    if (Object.keys(this.service).length) {
      let ticket: any = {
        step: 1,
        service_id: this.service.id,
        service_name: this.service.nombre,
        motive: Number(this.motive.value)
      };

      this.$store.dispatch("submitRequestStepOne", ticket);
    } else {
      this.$q.notify({
        message: this.$t("notification.no_service_selected") as string,
        color: "principal",
        position: "top",
        timeout: 5000
      });
    }
  }

  private selectService() {
    // if (e) this.service = e;
    this.$q.loading.show();
    this.$axios
      .get("servicios", {
        params: {
          filter: {
            where: { id: Number(this.$route.params.service_id) },
            include: [
              {
                relation: "servicio_url",
                scope: { 
                  where: { estado: 1 },
                  order: [ 'prioridad ASC' ],
                  fields: [
                    'id',
                    'id_servicio',
                    'url',
                    'nombre',
                    'tipo',
                    'descripcion',
                    'prioridad',
                    'estado'
                  ],
                }
              }
            ]
          }
        }
      })
      .then(response => {
        if (response.data.length > 0) {
          this.service = response.data[0];
          this.stepOneSubmit();
          this.$store.dispatch("setStateToOne");
          //this.$store.dispatch("storeService", response.data[0]);
        }
      })
      .catch(error => {})
      .finally(() => {
        this.$q.loading.hide();
      });
  }
}
